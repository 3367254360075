$color-theme-white: #ffffff;
$color-theme-red: #fe393b;
$color-theme-pink: #f50862;
$color-theme-purple: #a22fab;
$color-theme-deep-purple: #6542b2;
$color-theme-indigo: #3057b0;
$color-theme-blue: #009aed;
$color-theme-light-blue: #00adee;
$color-theme-cyan: #00bdd1;
$color-theme-teal: #009789;
$color-theme-green: #36ad5b;
$color-theme-light-green: #86c05a;
$color-theme-lime: #ced855;
$color-theme-yellow: #fee65a;
$color-theme-amber: #febd3b;
$color-theme-orange: #fe932e;
$color-theme-deep-orange: #fe4e2e;
$color-theme-brown: #7d5449;
$color-theme-grey: #9d9d9d;
$color-theme-blue-grey: #5a7e89;
$color-theme-text: #595979;

$color-theme-primary: $color-theme-blue;
$color-theme-secondary: $color-theme-green;
