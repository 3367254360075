@import './variable.scss';
@import '../../node_modules/antd/dist/antd.css';
@import '../../node_modules/font-awesome/css/font-awesome.css';
@import './chat-support.css';
@import './colors.scss';
@import './err-page.scss';
@import './box.scss';

.ant-layout {
  background: #fff;
}
.ant-message,
.ant-notification {
  z-index: 2003;
}
.ant-select-dropdown {
  z-index: 2003;
}
.app-modal {
  z-index: 2002;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.ant-row.ant-form-item {
  margin-bottom: 0;
}
.ant-form-item-label {
  padding-bottom: 0 !important;
}
.ant-form-item-label label {
  color: rgba(0, 0, 0, 0.4);
}
.app-layout {
  min-height: 100vh;
  margin-bottom: 60px;
}

.container-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-card-footer {
  border-top: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
  margin-bottom: -1px;
  background: transparent;
  padding: 12px 24px;
  zoom: 1;
  min-height: 48px;
  display: flex;
  justify-content: flex-end;
}

.ant-card-footer {
  .ant-row.ant-form-item {
    margin-bottom: 0;
  }
  .ant-btn {
    margin-right: 0.5rem;
  }
}

.ant-card-head-wrapper {
  .content-center {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    justify-content: center;
  }

  padding: 12px;
}

.btn-icon-only,
.btn-no-style {
  border: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0;

  &:focus {
    outline: none;
  }
}
.full-width {
  width: 100%;
}
.ant-modal-mask,
.ant-modal-wrap {
  z-index: 2002;
}
