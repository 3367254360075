.err-container {
  display: block;
  padding-top: 1rem;
  padding-right: 0.625rem;
  padding-bottom: 1rem;
  padding-left: 0.625rem;
  text-align: center;

  .err-code-container {
    width: 280px;
    height: 280px;
    border-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    border: 2px solid #01bcd4;

    padding-top: 28px;
    padding-right: 28px;
    padding-bottom: 28px;
    padding-left: 28px;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 1.5rem;
    margin-left: auto;
  }

  .err-code {
    width: 220px;
    height: 220px;
    border-radius: 50%;
    background-color: #01bcd4;
    color: $color-theme-white;
  }
  .err-code-title {
    font-size: 5.625rem;
    line-height: 220px;
    font-weight: 300;
  }
  .err-desc {
    font-size: 1.5rem;
    line-height: 2.5rem;
    margin-bottom: 1.5rem;
    font-weight: 300;
    opacity: 0.8;
  }
}
