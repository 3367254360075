.mck-box-top,
.km-sidemodal--modal-header {
  background-color: #ff8e40 !important;
}
.mck-box .mck-running-on {
  display: none !important;
}

.mck-sidebox-launcher,
.km-hide-logo {
  background-color: #ff8e40 !important;
  box-shadow: none !important;
}

#mck-msg-new.mck-btn,
#km-submit-chat-login.mck-btn {
  background-color: #ff8e40 !important;
  border: solid 1px #ff8e40 !important;
}

.chat-launcher-icon {
  height: 56px;
  width: 56px;
}

.mck-msg-left .mck-msg-box {
}

.mck-msg-right .mck-msg-box {
  background-color: #009fe8 !important;
  color: #fff !important;
}
