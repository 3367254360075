@import 'styles/variable.scss';

.loader-component {
  position: absolute;
  z-index: 1010;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .container-center {
    .error-component {
      height: 100px;
      width: 280px;
    }

    .error {
      padding: 8px 15px;
      border: 1px solid #ffa39e;
      background-color: #fff1f0;

      margin: 0;
      list-style: none;
      border-radius: 4px;
      font-weight: bold;
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5;
      color: rgba(0, 0, 0, 0.65);

      .reload-btn {
        margin-left: 4px;
        color: $color-theme-primary;
      }
    }
  }
}
