.border-bottom {
  border-bottom: 1px solid #e8e8e8;
}

.box-nowrap {
  outline: none;
  text-align: left;
  background-color: $color-theme-white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  padding: 0;
  margin: 0;

  &.header-tab {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: none;
    background-clip: none;
  }
  .border-bottom-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    .title {
      font-weight: bold;
    }
  }
  .border-top {
    border-top: 1px solid #e8e8e8;
  }

  .border-bottom {
    border-bottom: 1px solid #e8e8e8;
  }

  .content {
    padding-top: 14px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 14px;
    &.notb {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}

.box {
  outline: none;
  text-align: left;
  background-color: $color-theme-white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  padding-top: 14px;
  padding-right: 14px;
  padding-bottom: 14px;
  padding-left: 14px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;

  .border-bottom {
    border-bottom: 1px solid #e8e8e8;
  }
  .border-bottom-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    .title {
      font-weight: bold;
    }
  }
  .content {
    padding-top: 14px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 14px;
    &.notb {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}

.box.header {
  box-shadow: none;
  background-clip: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 16px;
  .page-title {
    margin: 0;
  }
}

.box-menu {
  padding-top: 4px;
  padding-right: 0px;
  padding-bottom: 4px;
  padding-left: 0px;
  margin: 0;
  text-align: left;
  background-color: $color-theme-white;
  outline: none;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
}

.list-menu {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  .item {
    padding: 5px 12px;
    margin: 0;
    clear: both;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.3s;
    line-height: 22px;
    box-sizing: border-box;
    text-align: -webkit-match-parent;
    i {
      margin-right: 0.5rem;
    }
  }
  .divider {
    height: 1px;
    overflow: hidden;
    background-color: #e8e8e8;
    line-height: 0;
    margin: 4px 0;
  }
  a {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    &:hover {
      color: #0076ff;
    }
    .item:hover {
      color: #0076ff;
    }
  }
}
